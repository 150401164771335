#intro {

    &::before {
      content: '';
      background-color: #FFFFFF;
      z-index: -2;
      // background-image: url('intro-background.jpg');
      // background-position: center;
      // background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #FFFFFF;
    width: 100%;
  
    .logo {
      position: relative;
      height: 40%;
      padding: 60px;
      pointer-events: none;
    }

    .design-vector {
      position: fixed;
      z-index: -1;
      bottom: 0px;
      width: 90%;
      opacity: 0.4;
    }

    .svg-inline--fa { 
      position: absolute;
      bottom: 20px;
      height: 50px;
      width: 50px;
    }
  
    // @media (prefers-reduced-motion: no-preference) {
    //   .logo {
    //     animation: App-logo-spin infinite 20s linear;
    //   }
    // }
  
    // @keyframes App-logo-spin {
    //   from {
    //     transform: rotate(0deg);
    //   }
    //   to {
    //     transform: rotate(360deg);
    //   }
    // }
  
  }
  