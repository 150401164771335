#page-header {
    position: fixed;
    height: auto;
    display: block;
    width: 100%;
    background-color: #00003A;
    font-size: 16px;
    color: black;
    border-bottom: 1px solid black;

    img {
        margin: 10px 15px;
        height: 50px;
    }

    ul {
        float: right;
        margin: 20px 50px;
        
        li {
            display: inline;
            margin: 0 15px;
        }
    }
}